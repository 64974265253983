.box {
  position: relative;
  padding: 1rem;
  z-index: 2;
  overflow: hidden;
  color: white;
}

.box::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 1rem;
  padding: 1px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.box::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("/img/onboarding/noise.png");
  border-radius: 1rem;
  opacity: 0.1;
  padding: 2px;
  z-index: 1;
  mix-blend-mode: multiply;
  pointer-events: none;
}

.tealBorder::before {
  background: linear-gradient(145deg, #A0E8ED, rgba(0, 0, 0, 0) 50%);
}
